<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.bg">
      <div class="container">
        <div class="profile-wrapper mt-4">
          <!-- first row begins -->
          <div class="row">
            <div class="col-sm-12 col-md-4 mb-4">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <div class="edit-card-section" style="float: right">
                    <ion-icon
                    hidden="true"
                    :icon="ellipsisHorizontalOutline"></ion-icon>
                  </div>
                  <div class="avatar-section mt-5">
                    <ion-avatar>
                      <ion-img  class="rounded-circle" :src="avatar" data-holder-rendered="true"></ion-img>
                    </ion-avatar>
                  </div>
                  <div class="info-section mt-4 text-center">
                    <div class="title">
                      <h3><strong>{{ fullName }}</strong></h3>
                    </div>
                    <div>
                      <p><span v-if="profile.occupation">{{profile.occupation}}</span> <br>
                      {{ miniAddress }} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-8 mb-4">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <div class="edit-card-section" style="float: right">
                    <ion-icon
                    id="trigger-button" @click="openPopover"
                    :icon="ellipsisHorizontalOutline">
                    </ion-icon>
                    <ion-popover 
                    :show-backdrop="false"
                    :is-open="isPopoverOpened"
                    @didDismiss="closePopover"
                    :arrow="false" trigger="trigger-button" side="left">
                      <ion-content>
                        <ion-item>
                          <button
                            @click.prevent="() => {
                              isPopoverOpened = false
                              navigateToOption('Account')
                            }"
                            class="btn">Update information
                          </button>
                        </ion-item>
                      </ion-content>
                    </ion-popover>
                    
                  </div>
                  <div class="info mt-5">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item mb-3" v-if="fullName" :style="settings.fg.li">
                        <div class="row"><div class="col-5"><strong>Name</strong></div>
                        <div class="col-7"> {{ fullName }}
                          <span v-if="profile.nickname" class="text-muted"> [ {{ profile.nickname }} ]</span></div>
                        </div>
                      </li>
                      <li class="list-group-item mb-3" v-if="profile.phone" :style="settings.fg.li">
                        <div class="row"><div class="col-5"><strong>Phone</strong></div>
                        <div class="col-7">{{ profile.phone }}</div>
                        </div>
                      </li>
                      <li class="list-group-item mb-3" v-if="profile.email" :style="settings.fg.li">
                        <div class="row"><div class="col-5"><strong>Email</strong></div>
                        <div class="col-7">{{ profile.email }}</div>
                        </div>
                      </li>
                      <li class="list-group-item mb-3" v-if="birthday" :style="settings.fg.li">
                        <div class="row"><div class="col-5"><strong>Birthday</strong></div>
                        <div class="col-7">{{ birthday }}</div>
                        </div>
                      </li>
                      <li class="list-group-item mb-3" v-if="fullAddress" :style="settings.fg.li">
                        <div class="row"><div class="col-5"><strong>Address</strong></div>
                        <div class="col-7">{{ fullAddress }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- first row ends -->
          <!-- second row starts -->
          <div class="row">
            <div class="col-sm-12 col-md-4  mb-4" v-if="profile.hobbies">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <div class="card-title text-muted mb-2">
                    <h3>Hobbies</h3>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li v-for="hobby in profile.hobbies" :key="hobby" class="list-group-item" :style="settings.fg">
                      <p>{{ hobby }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-8  mb-4" v-if="profile.languages">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <div class="card-title text-muted mb-2">
                    <h3>Languages</h3>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li v-for="language in profile.languages" :key="language" class="list-group-item" :style="settings.fg">
                      <p>{{ language }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-12 col-md-4  mb-4" v-if="settingsItems.length">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <div class="card-title text-muted mb-2">
                    <h3>Settings</h3>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li v-for="(item) in settingsItems" :key="item.id" class="list-group-item" :style="settings.fg">
                      <div class="row"><div class="col-5"><strong>{{getPropertyLabel(item.title)}}</strong></div>
                        <div class="col-7 text-center">{{ item.prop }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
          </div>
          <SystemSettingsModal/>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import router from "@/router";
import moment from "moment";
import { mapActions, createNamespacedHelpers } from "vuex";
import SystemSettingsModal from '../modals/SystemSettingsModal.vue'
import {
  ellipsisVerticalOutline,
  ellipsisHorizontalOutline,
} from "ionicons/icons"
import { 
  IonPage, IonIcon, IonPopover,IonContent, IonToolbar, IonHeader, 
  IonAvatar, IonImg,IonItem, 
} from "@ionic/vue"

const UserModule = createNamespacedHelpers('user')
const AvatarModule = createNamespacedHelpers('avatar')
const ProfileModule = createNamespacedHelpers('profile')
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: "Profile",
  components: {
    IonPage, IonHeader, IonContent, IonToolbar, IonIcon, SystemSettingsModal, 
    IonAvatar, IonImg, IonPopover, IonItem
  },
  data() {
    return {
      isPopoverOpened: false,
    };
  },
  setup() {
    return {
      ellipsisVerticalOutline,
      ellipsisHorizontalOutline,
    };
  },
  computed: {
    ...UserModule.mapGetters(['selectCurrentUser']),
    ...AvatarModule.mapGetters(['selectAvatar']),
    ...ProfileModule.mapGetters(['selectProfile']),
    ...SettingsModule.mapGetters([
    'selectUserSettings', 
    'selectApplicationSettings'
    ]),

    settings(){
      return this.selectUserSettings
    },
    avatar(){
      return this.selectAvatar
    },
    profile(){
      return this.selectProfile
    },
    birthday(){
      return moment(this.profile.birthday).format('LL')
    },
    fullName(){
      return `${this.profile.firstName} ${this.profile.lastName }`
    },
    application(){
      return this.selectApplicationSettings
    },
    fullAddress(){
      return `${ this.profile.address.street }, 
      ${ this.profile.address.city }, 
      ${this.profile.address.state}
      ${this.profile.address.zipcode}`
    },
    miniAddress(){
      return `${ this.profile.address.localRegion ? 
      this.profile.address.localRegion : ''}, 
      ${ this.profile.address.city }, 
      ${this.profile.address.state}`
    },
    settingsItems(){
      let properties
      let mySettings = []
      if(this.application){
        properties = Object.keys(this.application)

        if(properties && properties.length){
          properties = properties.filter(prop => {
            if(prop !== 'createdAt' && 
            prop !== 'updatedAt' && 
            prop !== '_id' && prop !== 'user' 
            && prop !== '__v' && prop !== 'foregroundLight'){
              return prop
            }
          })
          properties.forEach(prop => {
            mySettings.push({
              id: uuidv4(),
              prop: this.application[prop],
              title: prop
            })
          })
        }
      }
      return mySettings
    }
  },
  methods: {
    ...mapActions(["changePageTitle", 'navigateToOption']),
    ...ProfileModule.mapActions(['fetchProfile']),

    async setPageTitle() {
      await this.changePageTitle(router.currentRoute._value.name);
    },
    closePopover(){
      this.isPopoverOpened = false
    },
    openPopover() {
      this.isPopoverOpened = true
    },
    getPropertyLabel(property) {
      const found = property.toString().match(/[A-Z]/);
      if (found) {
        const characters = found[0];
        property = property.replace(/[A-Z]/, ` ${characters}`);
        return this.capitalizeFirstLetter(property);
      } else {
        return this.capitalizeFirstLetter(property);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  async mounted() {
    this.setPageTitle();
    const {_id: userId } = this.selectCurrentUser
    await this.fetchProfile(userId)
  },
};
</script>
<style scoped lang="scss">
  #container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 12%;
    transform: translateY(-50%);
  }
  ion-avatar{
    height: 160px;
    width: 160px;
    margin: auto;
  }
  .rounded-circle{
    background: none;
    border: none;
  }
  // ion-content {
  //   --background: #f5f6fa !important;
  // }
  // .card {
  //   border-radius: 0px;
  //   background-color: white;
  // }
</style>